<template>
  <b-sidebar id="add-edit-workorder-sidebar" :visible="isAddEditWorkorderSidebarActive" bg-variant="white"
    sidebar-class="sidebar-lg" shadow backdrop no-header right @hidden="() => {
      resetWorkOrderFields();
    }

      " @change="(val) => $emit('update:is-add-edit-workorder-sidebar-active', val)">
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{
            actionType == 1
              ? $i18n.t("Add New Work Order")
              : actionType == 2
                ? $i18n.t("Update Work Order")
                : $i18n.t("Delete Work Order")
          }}
        </h5>
        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form class="p-2" @submit.prevent="handleSubmit(submitForm)" @reset.prevent="resetForm">
          <b-form-group :label="$i18n.t('Status')">
            <v-select id="statusId" :disabled="actionType == 1" v-model="workOrderData.status"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="name" :reduce="(options) => options.id"
              :options="workOrderFields.statuses" class="select-size-lg">
              <template slot="selected-option" slot-scope="option">
                <span>{{ $t(option.name) }}</span>
              </template>
              <template #option="{ name }">
                <span> {{ $t(name) }}</span>
              </template>
            </v-select>
          </b-form-group>
          <b-form-group :label="$i18n.t('Priority')">
            <v-select id="priorityId" v-model="workOrderData.priority"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="name" :reduce="(options) => options.id"
              :options="workOrderFields.priorities" class="select-size-lg">
              <template slot="selected-option" slot-scope="option">
                <span>{{ $t(option.name) }}</span>
              </template>
            </v-select>
          </b-form-group>
          <b-form-group :label="$i18n.t('Workcenter')">
            <v-select id="workcenterId" v-model="workOrderData.lineId"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="name" :reduce="(options) => options.id"
              :options="workOrderFields.workCenters" class="select-size-lg">
              <template slot="selected-option" slot-scope="option">
                <span>{{ $t(option.name) }}</span>
              </template>
            </v-select>
          </b-form-group>
          <b-form-group :label="$i18n.t('Assignee')">
            <v-select id="AssigneeId" v-model="workOrderData.assignee"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="name" :reduce="(options) => options.id"
              :options="assigneeOptions" class="select-size-lg">
            </v-select>
          </b-form-group>
          <b-form-group :label="$i18n.t('Job Name')">
            <b-form-input id="workOrdername" v-model="workOrderData.name" autofocus trim
              :placeholder="$i18n.t('Job Name')" />
          </b-form-group>
          <b-form-group :label="$i18n.t('Description')">
            <b-form-textarea id="description" v-model="workOrderData.description" placeholder="Enter something..."
              rows="3" max-rows="6"></b-form-textarea>
          </b-form-group>
          <b-form-group :label="$i18n.t('Solution')">
            <b-form-textarea id="solution" v-model="workOrderData.solution" autofocus trim
              :placeholder="$i18n.t('Solution')" />
          </b-form-group>


          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-2" type="submit">
              {{ actionType == 1 ? $i18n.t("Add") : $i18n.t("Update") }}
            </b-button>
            <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="button" variant="outline-secondary"
              @click="resetWorkOrderFields">
              {{ $i18n.t("Cancel") }}
            </b-button>
            <b-button v-if="actionType == 2" v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="button" class="mx-2"
              variant="danger" @click="deleteWorkOrder">
              {{ $t("Delete") }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BButton,
  BFormTextarea,
  BFormInvalidFeedback,
} from "bootstrap-vue";
import { ref, computed, toRefs, watch } from "@vue/composition-api";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email, url } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
//import store from "@/store";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapGetters } from "vuex";
import flatPickr from "vue-flatpickr-component";
import store from "@/store";
import { GetLocalDate } from "@/utils/utils";

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    vSelect,
    BFormInvalidFeedback,
    BFormTextarea,
    ValidationObserver,
    ValidationProvider,
    flatPickr,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isAddEditWorkorderSidebarActive",
    event: "update:is-add-edit-workorder-sidebar-active",
  },
  props: {
    isAddEditWorkorderSidebarActive: {
      type: Boolean,
      required: true,
    },
    actionType: {
      type: Number,
      required: true,
    },
    selectedWorkOrder: {
      type: Object,
      required: false,
      default: () => {
        return {
          id: null,
          status: null,
          priority: null,
          name: null,
          assignee: null,
          line: null,
          description: null,
          solution: null,
          startDate: null,
          endDate: null,
        }
      }
    },
  },
  data() {
    return {
      required,
    };
  },
  methods: {
    notification() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Process Successful",
          icon: "BellIcon",
        },
      });
    },
  },

  setup(props, { emit }) {
    const { actionType, selectedWorkOrder, isAddEditWorkorderSidebarActive } =
      toRefs(props);

    const assigneeOptions = computed(
      () => store.getters["pamis-work-order-module/GetAssignees"]
    );
    const blankWorkOrderData = {
      id: null,
      status: null,
      priority: null,
      name: null,
      assignee: null,
      lineId: null,
      description: null,
      solution: null,
      startDate: null,
      endDate: null,
    };

    const workOrderData = ref(JSON.parse(JSON.stringify(blankWorkOrderData)));

    const workOrderFields = computed(() => {
      return store.getters["pamis-work-order-module/GetWorkOrderFields"];
    });

    watch(isAddEditWorkorderSidebarActive, (newVal) => {
      if (actionType.value == 2) {
        workOrderData.value = JSON.parse(
          JSON.stringify(selectedWorkOrder.value)
        );
      }
      if (newVal && actionType.value == 1)
        workOrderData.value.status = 1

    });

    const resetWorkOrderFields = () => {
      workOrderData.value = blankWorkOrderData;
      emit("update:is-add-edit-workorder-sidebar-active", false);
    };




    const submitForm = () => {
      const data = workOrderData.value;

      const formData = {
        id: data.id,
        name: data.name,
        assignee: data.assignee,
        startDate: GetLocalDate(data.startDate),
        endDate: GetLocalDate(data.endDate),
        description: data.description,
        lineId: data.lineId,
        solution: data.solution,
        priority: data.priority,
        status: data.status,
      };
      let actionName = "";

      if (actionType.value == 1) {
        formData.startDate = new Date(); //on create task
        formData.status = 1;
        // Add New
        actionName = "AddNewWorkOrder";
      } else if (actionType.value == 2) {
        actionName = "EditWorkOrder";
        if (data.status == 3) {
          // if its Done
          formData.endDate = new Date();
        }
      }
      store
        .dispatch(`pamis-work-order-module/${actionName}`, formData)
        .then(() => {
          resetWorkOrderFields();
          emit("refetch-data");
        });
    };

    const deleteWorkOrder = () => {
      store
        .dispatch(
          "pamis-work-order-module/DeleteWorkOrder",
          workOrderData.value.id
        )
        .then(() => {
          resetWorkOrderFields();
          emit("refetch-data");
        });
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetWorkOrderFields);

    return {
      //  onSubmit,
      workOrderFields,
      resetWorkOrderFields,
      blankWorkOrderData,
      submitForm,
      workOrderData,

      refFormObserver,

      deleteWorkOrder,
      assigneeOptions,
      getValidationState,
      resetForm,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
