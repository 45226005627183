<template>
  <div>
    <WorkOrderSideBar :isAddEditWorkorderSidebarActive.sync="isAddEditWorkOrderSidebarActive"
      :selectedWorkOrder="selectedWorkOrderItem" :action-type="actionType" @refetch-data="fetchData" />
    <b-card>
      <b-row>
        <b-col cols="12" md="6" lg="3" class="p-50">
          <b-card class="task-status-card">
            <b-card-title class="d-flex justify-content-between">

              <span class="d-flex">
                <feather-icon icon="Edit3Icon" size="16" class="mr-1 mt-25" />
                <h4>{{ $t("To Do") }}</h4>
              </span>
              <span>
                <b-button @click="WorkOrderAddNew()" variant="primary"
                  style="border: 1px solid #c0c0c0; background-color: white" class="p-50 tooltip-button">
                  <div class="tooltip">

                  </div>
                  <div class="tooltip-text">{{ $t("Add New Work Order") }}</div>

                  <feather-icon icon="PlusIcon" color="#fff">

                  </feather-icon>
                </b-button>
              </span>
            </b-card-title>
            <div v-for="toDoItem in workOrderItems.toDoItems" :key="toDoItem.id">
              <WorkOrderItem :itemFields="toDoItem" @ItemClick="onWorkOrderItemClick(toDoItem)" />
            </div>
          </b-card>
        </b-col>
        <b-col cols="12" md="6" lg="3" class="p-50">
          <b-card class="task-status-card">
            <b-card-title class="d-flex justify-content-between">
              <span class="d-flex">
                <feather-icon icon="RefreshCcwIcon" size="16" class="mr-1 mt-25" />
                <h4>{{ $t("Ongoing") }}</h4>
              </span>
            </b-card-title>

            <div v-for="onGoingItem in workOrderItems.onGoingItems" :key="onGoingItem.id">
              <WorkOrderItem :itemFields="onGoingItem" @ItemClick="onWorkOrderItemClick(onGoingItem)" />
            </div>
          </b-card>
        </b-col>
        <b-col cols="12" md="6" lg="3" class="p-50">
          <b-card class="task-status-card">
            <b-card-title class="d-flex justify-content-between">
              <span class="d-flex">
                <feather-icon icon="CheckCircleIcon" size="16" class="mr-1 mt-25" />
                <h4>{{ $t("Done") }}</h4>
              </span>
            </b-card-title>

            <div v-for="doneItem in workOrderItems.doneItems" :key="doneItem.id">
              <WorkOrderItem :itemFields="doneItem" @ItemClick="onWorkOrderItemClick(doneItem)" />
            </div>
          </b-card>
        </b-col>
        <b-col cols="12" md="6" lg="3" class="p-50">
          <b-card class="task-status-card">
            <b-card-title class="d-flex justify-content-between">
              <span class="d-flex">
                <feather-icon icon="PauseCircleIcon" size="18" class="mr-1 mt-25" />
                <h4>{{ $t("On Hold") }}</h4>
              </span>
            </b-card-title>

            <div v-for="onHoldItem in workOrderItems.onHoldItems" :key="onHoldItem.id">
              <WorkOrderItem :itemFields="onHoldItem" @ItemClick="onWorkOrderItemClick(onHoldItem)" />
            </div>
          </b-card>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import { ref, computed } from "@vue/composition-api";
import {
  BCard,
  BRow,
  BCol,
  BCardTitle,
  BButton,
  BCardBody,
} from "bootstrap-vue";
import WorkOrderSideBar from "./components/WorkOrderSideBar.vue";
import WorkOrderItem from "./components/WorkOrderItem.vue";
import workOrderStoreModule from "./workOrderStoreModule";
import store from "@/store";
import useWorkOrderBoard from "./composables/useWorkOrderBoard";
export default {
  components: {
    WorkOrderItem,
    WorkOrderSideBar,
    BRow,
    BCardBody,
    BCol,
    BCard,
    BCardTitle,
    BButton,
  },
  setup() {
    const PAMIS_WORK_ORDER_MODULE = "pamis-work-order-module";
    if (!store.hasModule(PAMIS_WORK_ORDER_MODULE)) {
      store.registerModule(PAMIS_WORK_ORDER_MODULE, workOrderStoreModule);
    }
    const actionType = ref(1);
    const isAddEditWorkOrderSidebarActive = ref(false);

    const { fetchData, workOrderItems } = useWorkOrderBoard();
    const selectedWorkOrderItem = ref();

    const onWorkOrderItemClick = (item) => {
      actionType.value = 2;
      selectedWorkOrderItem.value = item;
      isAddEditWorkOrderSidebarActive.value = true;
    };

    const WorkOrderAddNew = () => {
      actionType.value = 1;
      isAddEditWorkOrderSidebarActive.value = true;
    };

    return {
      isAddEditWorkOrderSidebarActive,
      selectedWorkOrderItem,
      onWorkOrderItemClick,
      workOrderItems,
      actionType,
      fetchData,
      WorkOrderAddNew,
    };
  },
};
</script>

<style scoped>
.tooltip-button {
  position: relative;
  display: inline-block;
}

.tooltip-text {

  visibility: hidden;
  background-color: #fff;
  color: #7a7a7a;
  text-align: center;
  padding: 5px;
  border-radius: 4px;
  position: absolute;
  bottom: 120%;
  /* Adjusts tooltip position above the button */
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  /* Prevents text from wrapping */
  opacity: 0;
  transition: opacity 0.3s;
  z-index: 10;
  box-shadow: #5e5873 0px 0px 5px 0px !important;
}

.tooltip-button:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}

.task-status-card {
  height: 100%;
  min-height: 400px;
  background-color: rgb(240, 240, 240) !important;
}
</style>