import { ref, computed } from '@vue/composition-api'
import store from '@/store'
import workorderjobs from "@/mockdata/workorderjobs.json"
import i18n from '@/libs/i18n'

export default function useWorkOrderBoard() {

    const fetchData = () => { store.dispatch("pamis-work-order-module/GetWorkOrderItems") };

    store.dispatch("pamis-work-order-module/GetWorkOrderFields")
    store.dispatch("pamis-work-order-module/GetAssignees")
    fetchData();



    const workOrderItems = computed(() => {
        const allItems = store.getters["pamis-work-order-module/GetWorkOrderItems"]
        const toDoItems = allItems.filter(e => e.status == 1)
        const onGoingItems = allItems.filter(e => e.status == 2)
        const doneItems = allItems.filter(e => e.status == 3)
        const onHoldItems = allItems.filter(e => e.status == 4)
        return { toDoItems, onGoingItems, doneItems, onHoldItems }
    })




    return {
        fetchData,
        workOrderItems,
    }
}
